<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
	export default {
		data(){
			return {
				
			}
		},
		created(){
			this.getserver()
		},
		methods: {
			// 获取客服链接
			async getserver() {
			  const { data } = await this.$http.get('/home/index/serviceurl')
			  if (data) {
			    if (data.code === 200) {
					console.log('获取客服链接===>',data)
					let script = document.createElement("script");
					script.type = "text/javascript";
					script.src = data.data;
					document.body.appendChild(script);
			    }
			  }
			},
		}
	}
</script>
